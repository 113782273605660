.handleTheProfileBody {
  /* background-color: #1A1A25; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 78vh;
}

.imageDivClass {
  display: flex;
  align-items: start;
  justify-content: start;
}

.gray {
  color: #6c757d;
}

.transfer-btn-lg {
  position: relative;
  bottom: 15px;
  left: 10px;
}

.position-change {
  margin-right: -200px !important;
}

.profileTitles {
  font-family: Montserrat;
  font-size: 1.5rem;
  margin-top: 0rem;
}

.profileImgInput {
  background-color: transparent;
  width: 60%;
  margin: 20px auto;
  color: white;
}

.imgProfileDiv {
  margin-top: -1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.profile-image {
  width: 200px;
  height: 200px;
}

.profileForm {
  background-color: #060b1ab7;
  border-radius: 8px;
}

.profileTextBtn {
  display: none;
}

.desktopProfile {
  display: block;
}

.topmarginn {
  margin-top: 0;
}

.buttonsbelow_styles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0 !important;
}

.phone-btn-margin-left {
  margin-left: 11px !important;
}

.pc_btns {
  display: none;
}

.phn_btns {
  display: block;
}

.wallet-login-button-in-profile {
  border: none;
  color: black;
  width: 122px;
  background-color: rgb(21, 64, 127);
  color: white;
  font-size: 14px;
}

.profile-wallet-login .button-metamask {
  padding-inline: 0px !important;
  width: 140px !important;
  border-radius: 0px !important;
}

/* .membership-status-box {
  display: flex;
  gap: 20px;
} */

.profile-and-membership {
  display: flex;
  gap: 20px;
}

.membership-status {
  color: orange;
}

.get-all-stars {
  color: orange;
  font-size: 12px;
}

.become-premium a {
  color: gold !important;
}

.gtr-info-container {
  position: absolute;
  min-width: max-content;
  background-color: rgb(21, 64, 127);
  padding-inline: 10px;
  padding-block: 6px;
  font-size: 14px;
  border-radius: 6px;
  z-index: 10;
}

.gtr-info-container p {
  margin-bottom: 0px;
}

.transfer-button {
  border: 0px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 0;
  padding-block: 0;
  height: 20px;
  width: 20px;
}

.transfer-button img {
  width: 15px;
}

.your-earnings-button {
  border: none;
  margin-bottom: 9px;
  padding-inline: 10px;
  padding-block: 4px;
  border-radius: 4px;
  background: #EA906C;
}

/* SHIN ANIMATION FOR STAR */

.star-container {
  position: relative;
  overflow: hidden;
  width: 30px;
  border-radius: 40px;
}

.star-container div {
  position: absolute;
  top: 0;
  height: 100%;
  left: -100px;
  width: 40%;
  background: linear-gradient(to right, white, transparent 50%);
  -webkit-text-fill-color: transparent;
  animation: shine 2.5s infinite;
  transform: skewX(-30deg);
  overflow: hidden;
}

@keyframes shine {
  0% {
    left: -10%;
  }

  100% {
    left: 110%
  }
}




@media only screen and (max-width: 720px) {
  .profileTextBtn {
    display: block;
  }

  .desktopProfile {
    display: none;
  }

  .topmarginn {
    margin-top: -72px;
  }

  .buttonsbelow_styles {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0 !important;
  }

  .phone-btn-margin-left {
    margin-left: 0 !important;
  }

  .membership-status {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .profile-image {
    width: 185px;
    height: 185px;
  }

  .profile-stars {
    margin-bottom: 6px;
  }

  .become-premium {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .gtr-info-container {
    right: 0px;
  }

}

@media only screen and (max-width: 580px) {
  .profileTitile {
    margin-left: 33px !important;
  }

  .imageDivClass {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .clickText {
    margin-right: 85px !important;
  }

  .mobilecenter {
    display: block;
    text-align: center;
  }
}

.profileUser {
  display: flex;
}

.profileUser input {
  width: 82%;
}

@media screen and (min-width: 768px) {
  .pc_btns {
    display: block;
  }

  .phn_btns {
    display: none;
  }
}

@media screen and (min-width: 880px) {
  .button-margin {
    margin-left: 12px !important;
  }
}

@media screen and (max-width: 880px) {
  .button-margin {
    margin-left: 12px !important;
  }
}

.profileUser button {
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: #dc3545;
  color: white;
}

.profileBtn {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: #dc3545;
  color: white;
  margin: 10px auto;
  margin-top: 1rem;
  width: 7rem;
  cursor: pointer;
  text-align: center;
}

.profileBtn.disabled {
  background-color: #da747e;
}

.profileInput {
  background-color: transparent;
  color: #a2a2a2;
  border: 1px solid #a2a2a24b;
}

.otpBtn {
  padding: 8px;
  border: none;
  border-radius: 5px;
  background-color: #dc3545;
  color: white;
  margin: 10px auto;
}

.profileTitle {
  color: white;
  margin-top: 6.5rem;
  text-align: start;
}

.userDetails p {
  color: white;
  text-align: start;
  margin-bottom: 0rem;
  margin-top: 10px;
}

.userDetails input {
  width: 100%;
  padding: 6px;
  background-color: transparent;
  border: 1px solid #6a6d7481;
  border-radius: 6px;
  color: rgb(177, 167, 167);
}

.profileImg {
  width: 50%;
  background-color: transparent;
  color: white;
  margin: 1rem auto;
}

.errorMsg {
  margin: -0.4rem 0;
  font-weight: 500;
}

.btn-section {
  margin-top: -90px !important;
}

@media only screen and (max-width: 1080px) {
  .profileImgInput {
    width: 80%;
  }

  .profileUser input {
    width: 78.7%;
  }
}

@media only screen and (max-width: 990px) {
  .profileImgInput {
    width: 80%;
  }

  .profileUser input {
    width: 70.5%;
  }
}

@media only screen and (max-width: 768px) {
  .btn-section {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 725px) {
  .profileImgInput {
    width: 80%;
  }

  .imageDivClass {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .handleTheProfileBody {
    /* background-color: #1A1A25; */
    margin-top: 50px;
    padding-top: 50px;
    height: auto;
  }

  .profileUser input {
    width: 81.2%;
  }

  .social-div {
    display: flex !important;
    justify-content: center !important;
  }
}

@media only screen and (max-width: 535px) {
  .profileImgInput {
    width: 80%;
  }

  .imageDivClass {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profileUser input {
    width: 74.4%;
  }
}

@media only screen and (max-width: 394px) {
  .profileImgInput {
    width: 80%;
  }

  .imageDivClass {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profileUser input {
    width: 71.5%;
  }
}

@media only screen and (max-width: 361px) {
  .profileImgInput {
    width: 80%;
  }

  .imageDivClass {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profileUser input {
    width: 70.5%;
  }
}

/* BUTTON IN WALLET MODAL */
.kyc-profile-btn {
  background: linear-gradient(-30deg, #571b94 50%, #461772 50%);
  padding: 8px 12px;
  border: 0;
  /* margin: 0 auto !important; */
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #e6d4f7;
  font-size: 16px;
  /* letter-spacing: 2.5px; */
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  /* -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5); */
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;

  -webkit-animation: glowing-kyc 1500ms infinite;
  -moz-animation: glowing-kyc 1500ms infinite;
  -o-animation: glowing-kyc 1500ms infinite;
  animation: glowing-kyc 1500ms infinite;
}

@keyframes glowing-kyc {
  0% {
    background-color: #9b31ff;
    -webkit-box-shadow: 0 0 3px #9b31ff;
  }

  50% {
    background-color: #9b31ff;
    -webkit-box-shadow: 0 0 20px #9b31ff;
  }

  100% {
    background-color: #9b31ff;
    -webkit-box-shadow: 0 0 3px #9b31ff;
  }
}

.kyc-profile-btn::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #9985ad;
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}

.kyc-profile-btn:hover::before {
  opacity: 0.2;
}

.kyc-profile-btn span {
  position: absolute;
}

.kyc-profile-btn span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear,
      right top,
      left top,
      from(rgba(26, 8, 43, 0)),
      to(#b366ff));
  background: linear-gradient(to left, rgba(26, 8, 43, 0), #b366ff);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.kyc-profile-btn span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      from(rgba(26, 8, 43, 0)),
      to(#b366ff));
  background: linear-gradient(to top, rgba(26, 8, 43, 0), #b366ff);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.kyc-profile-btn span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(rgba(26, 8, 43, 0)),
      to(#b366ff));
  background: linear-gradient(to right, rgba(26, 8, 43, 0), #b366ff);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.kyc-profile-btn span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(26, 8, 43, 0)),
      to(#b366ff));
  background: linear-gradient(to bottom, rgba(26, 8, 43, 0), #b366ff);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

/* swal */
.modal_class_success {
  background-color: #2d313a !important;
  color: white !important;
}

.swal-footer {
  display: flex;
  justify-content: center !important;
}

.modal_class_success .swal-icon::before {
  background-color: transparent;
}

.modal_class_success .swal-icon::after {
  background-color: transparent;
}

.swal-content {
  padding: 0 20px;
  margin-top: 9px;
  font-size: 13px;
  overflow-wrap: break-word !important;
  color: white !important;
}

.swal-icon--success__hide-corners {
  display: none;
}