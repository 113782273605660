.faq-container {
  background: linear-gradient(90deg, #232b59 0%, #332d6b 100%);
  padding-block: 40px;
  margin-block: 45px;
}

.faq-q-container {
  padding-top: 18px;
}

.faq-header {
  margin-bottom: 38px;
}

.each-question {
  margin-bottom: 30px;
}

.each-question {
  color: white;
}

.question-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.que-toggle-button {
  border: none;
  background-color: transparent;
}

.ans {
  margin-top: 20px;
  color: #ffffffcc;
  transition: .5s all ease-in-out;
}

@media only screen and (max-width: 768) {
  .faq-q-container {
    padding-top: 40px;
  }
}