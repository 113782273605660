/*******************************************/
:root {
  --disabledButtonBg: #08146f;
  --borderPageColor: rgb(195, 22, 22);
  --borderRadius: 0.5rem;
  --btn-focus-scale: 0.95;
  --align-main-div: center;
  --buttonSize: 33px;
  /* 1rem = 16px */
  --padding-mainDiv: 8px 0px;
  /* 
    --padding-mainDiv: top/bottom left/right;
  */
}

.buttonDisable {
  color: white !important;
  background-color: var(--disabledButtonBg) !important;
  cursor: default !important;
}

.buttonPage {
  --borderPage: var(--borderPageColor) !important;
}

.mainDiv {
  display: flex;
  justify-content: var(--align-main-div);
  align-items: center;
  padding: 8px 0;
}


.button {
  display: inline-flex;
  flex-shrink: 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;


  text-align: center;

  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  border-radius: var(--borderRadius, 0.5rem);
  border: 1px solid var(--borderPage, transparent);

  font-weight: 600;
  text-transform: uppercase;
  height: var(--buttonSize, 2rem)
      /* 32px */
  ;
  padding-left: calc(var(--buttonSize, 2rem) - 1.25rem)
      /* 12px */
  ;
  padding-right: calc(var(--buttonSize, 2rem) - 1.25rem)
      /* 12px */
  ;
  min-height: var(--buttonSize, 2rem)
      /* 32px */
  ;
  font-size: calc(var(--buttonSize, 2rem) - 1.125rem)
      /* 14px */
  ;

}

.button:hover {
  background-color: blue;
  color: white !important;
}

.button:active:hover,
.button:active:focus {
  animation: none;
  transform: scale(var(--btn-focus-scale, 0.95));
}




.button-group {
  display: flex;
  flex-wrap: wrap;
}

.button-group button {
  border-radius: 0px;
}

.button-group button:nth-child(1) {
  --borderRadius: 0.5rem 0 0 0.5rem;
  border-radius: var(--borderRadius);
}

.button-group button:nth-last-child(1) {
  --borderRadius: 0 0.5rem 0.5rem 0;
  border-radius: var(--borderRadius);
}