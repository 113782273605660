.prize-name {
    font-size: 16px !important;
  }

  .priceOffer {
    font-size: 16px !important;
  }

  .prize-btn {
    background: #8522a5;
    border: none;
    color: white;
    /* padding left and right */
    padding: 5px 20px;
    height: 35px;
    border-radius: 1px;
  }
  


  @media only screen and (min-width: 366px) and (max-width: 380px) {
    .prize-name {
      font-size: 15px !important;
    }

    .priceOffer {
        font-size: 13px !important;
        display: "block"
      }
    

      .prize-btn {
        font-size: 12px !important;
        /* height: unset !important; */
      }
    
  }

  @media only screen and (max-width: 365px) {
    .prize-btn {
      font-size: 12px !important;
      /* height: unset !important; */
    }

    
  .priceOffer {
    font-size: 13px !important;
    display: "block"
  }

  .prize-btn {
    font-size: 12px !important;
    /* height: unset !important; */
  }
  }
  

  @media only screen and (max-width: 520px) {  
    .priceOffer {
        font-size: 14px !important;
        display: block;
    }

    .prize-btn {
        font-size: 16px !important;
        /* height: unset !important; */
      }
    
  }  
