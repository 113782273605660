.video-div {}

.video-div video {
  max-width: 100%;
  height: auto;
  border-top: 5px solid #303030;
  border-bottom: 5px solid #303030;
}

.aboutnft-title {
  font-size: 36px;
  font-weight: 900;
  line-height: 48px;
}

/* .nft-bg-div {
  border-top: 5px solid #303030;
  border-bottom: 5px solid #303030;
  background: url("../../assets/images/aboutnft/30.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */

.nft-minting-div {
  background: #060d29;
  border-top: 5px solid #303030;
  border-bottom: 5px solid #303030;
}

/* .nft-background {
  background-image: url("../../assets/images/aboutnft/37.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 95%;
} */

.buy-spoint-button{
  min-width: 49% !important;
}

.earning-tab{
  min-width: 150px !important;
}

@media screen and (max-width: 767px){
  .earning-tab{
    min-width: 100% !important;
  }
}
@media screen and (max-width: 768px){
  .earning-tab{
    min-width: 100% !important;
  }
}
@media screen and (max-width: 500px){
  .buy-spoint-button {
    min-width: 96% !important;
  }
}
@media screen and (max-width: 504px) {
  .buy-spoint-button {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 406px) {
  .buy-spoint-button {
    font-size: 8px !important;
  }
}