.container-card {
    background-color: #272D47;
    border-radius: 10px;
    padding: 10px 15px;
}
.send-grt-form input{
    width: 100%;
    border: 1px solid gray;
    margin-bottom: 4px;
    border-radius: 5px;
}

.send-grt-form label {
    color: white;
    font-size: 12px;
}

.send-grt-form button{
   margin-top: 15px;
}