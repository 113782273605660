.pagination ul {
  margin: 20px auto 0 auto;
}

.pagination ul {
  display: flex;
  align-items: center;
}

.pagination ul li {
  list-style: none;
}

.anticon-close-circle svg {
  color: gray !important;
}

.pagination ul li a {
  list-style: none;
  background-color: rgb(46, 46, 46);
  color: white;
}

.pagination .page-item.disabled .page-link {
  background-color: lightgray;
  color: black;
  border: none;
}

.pagination .page-item.active .page-link {
  background-color: white;
  color: black;
  border: none;
}

.accordian-sm {
  display: none !important;
}

.btn-main {
  background: linear-gradient(to right, #1081e8, #f32177);
}

.horse-animation-demo {
  width: 100%;
  height: 70vh;
  border: 2px solid;
}

.headers-action-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 769px) {
  .accordian-lg {
    display: none !important;
  }

  .accordian-sm {
    display: block !important;
  }

  .date-container {
    display: flex;
    justify-content: center !important;
  }

  .pagination li a {
    padding: 8px 12px 8px 12px;
    font-size: 13px;
  }

  .headers-action-container {
    flex-direction: column;
  }  
}
