.pagination ul {
  margin: 20px auto 0 auto;
}

.pagination ul {
  display: flex;
  align-items: center;
}

.pagination ul li {
  list-style: none;
}

.anticon-close-circle svg {
  color: gray !important;
}

.pagination ul li a {
  list-style: none;
  background-color: rgb(46, 46, 46);
  color: white;
}

.pagination .page-item.disabled .page-link {
  background-color: lightgray;
  color: black;
  border: none;
}

.pagination .page-item.active .page-link {
  background-color: white;
  color: black;
  border: none;
}

.accordian-sm {
  display: none !important;
}

.accordation-header button {
  background-color: #2e2c2c;
  color: #fff;
  font-weight: 700;
}

.accordion-button:after {
  background-image: url(https://img.icons8.com/color/48/000000/down-squared.png);
}

.play-results-button-container {
  display: flex;
  align-items: center;
  justify-content: start;
}

@media only screen and (max-width: 769px) {
  .accordian-lg {
    display: none !important;
  }

  .accordian-sm {
    display: block !important;
  }

  .date-container {
    display: flex;
    justify-content: center !important;
  }

  .pagination li a {
    padding: 8px 12px 8px 12px;
    font-size: 13px;
  }

  .play-results-button-container {
    flex-direction: column;
  }
}