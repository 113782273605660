.each-box {
  text-align: center;
  color: white;
  border-radius: 10px;
  overflow: hidden;
  height: 294px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}
.box-heading {
  color: #fbb206;
  font-size: 30px;
  font-weight: bold;
}
.box-heading2 {
  color: #fbb206;
  font-size: 22px;
  font-weight: bold;
}
.box-sub-text {
  font-size: 21px;
  font-weight: 500;
}
.box-sub-text2 {
  font-size: 17px;
  font-weight: 500;
}
.package-br {
  display: none;
}

.net-worth-box-container .box-1 {
  background: url("../../Assets/Images/packd1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.net-worth-box-container .box-2 {
  background: url("../../Assets/Images/packd2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.net-worth-box-container .box-3 {
  background: url("../../Assets/Images/packd3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.net-worth-box-container .box-4 {
  background: url("../../Assets/Images/package4.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.net-worth-usd-container {
  padding-block: 50px;
  background: linear-gradient(90deg, #332d6b 0%, #232b59 100%);
}

.sec-title-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  text-align: center;
}

.sec-title1 {
  color: #6095ff;
}
.underline-style1 {
  position: relative;
}

.underline-style1::after {
  display: block;
  content: "";
  width: 50%;
  height: 2px;
  background: #6095ff;
  position: absolute;
  bottom: 10;
  left: 86px;
}
.sec-title2 {
  color: #83ce5f;
}
.underline-style2 {
  position: relative;
}

.underline-style2::after {
  display: block;
  content: "";
  width: 50%;
  height: 2px;
  background: #83ce5f;
  position: absolute;
  bottom: 10;
  left: 86px;
}
.sec-title3 {
  color: #f0522a;
}
.underline-style3 {
  position: relative;
}

.underline-style3::after {
  display: block;
  content: "";
  width: 50%;
  height: 2px;
  background: #f0522a;
  position: absolute;
  bottom: 10;
  left: 50px;
}

.gtr-worth-usd-container {
  padding-block: 50px;
}

.buy-now-button {
  display: block;
  margin-inline: auto;
  --slant: 0.7em;
  border: none;
  background: #4583ff;
  color: white;
  padding-inline: 30px;
  padding-block: 6px;
  font-weight: 500;
  font-size: 18px;
  margin-block: 25px;
  clip-path: polygon(
    0 0,
    calc(100% - var(--slant)) 0,
    100% var(--slant),
    100% 100%,
    var(--slant) 100%,
    0 calc(100% - var(--slant))
  );
}

@media only screen and (max-width: 430px) {
  .package-br {
    display: unset;
  }
  .sec-title1 {
    padding-inline: 5px;
  }
}
