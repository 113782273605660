.payment-wrapper {
    background-color: #272d47;
    border-radius: 12px;
    padding: 30px 25px;
    word-wrap: break-word;
}

.flx {
    word-wrap: break-word;
    flex-wrap: wrap;
}

.bsc-input {
    width: 100%;
}

.bsc-input label {
    color: white !important;
}

#outlined-basic {
    color: white;
    border-color: white;
}

.bsc-input .MuiTextField-root {
    width: 100%;
}

.bsc-input .MuiInputBase-root {
    width: 100%;
    border-color: white;
}

.bsc-input .MuiOutlinedInput-notchedOutline {
    border-color: white;
}

.bsc-input .MuiOutlinedInput-notchedOutline:hover {
    border-color: white;
}

.bsc-input .MuiInputBase-root::before {
    border-color: white !important;
}

.border {
    border-color: white !important;
}

.button-18 {
    align-items: center;
    /* background: linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color-2) 100%); */
    background: rgb(194, 116, 0);
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    margin: 0.8rem 0;
} 

.button-18:disabled {
    cursor: not-allowed;
    background: linear-gradient(to right, #8364E2 0%, #8505ab 100%);
    color: rgba(0, 0, 0, .3);
}

.button-18:hover, .button-18:focus {
    background-color: #004c55;
    /* background-color: linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color-2) 100%); */
    color: #ffffff;
}

@media screen and (max-width: 992px) {
    .payment-wrapper {
        margin-right: 25px;
    }
    .button-18 {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 576px) {
    .payment-wrapper {
        padding: 30px 15px;
    }

    .payment-wrapper h5 {
        word-break: break-word;
    }
}

@media screen and (max-width: 440px) {
    .pay .button-18 {
        /* margin-top: 5px !important; */
        margin-bottom: 0px !important;
    }
    .button-18 {
        /* margin-top: 5px !important; */
        margin-bottom: 20px !important;
    }
}