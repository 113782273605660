.navbar-container {
  background-color: #332d6b;
  padding-block: 10px;
}

.navbar-main {
  margin-inline: 36px;
}

.navbar-logo {
  height: 60px;
}

.navbar-hamburger-button {
  border: none;
  background-color: white;
  border-radius: 5px;
  padding-block: 4px;
}

.large-nav-items-container {
  display: flex;
  gap: 50px;
  align-items: center;
}

.large-nav-items-container li {
  list-style: none;
}

.large-nav-items-container li a {
  color: white;
  text-decoration: none;
  /* font-family: "Inter"; */
  font-weight: 500;
}

.offcanvas {
  --bs-offcanvas-width: 286px;
}

.sign-in-button {
  border: none;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
  padding-inline: 8px;
  padding-block: 5px;
  background-color: white;
  color: #0b0b17;
  border: 1px solid #0b0b17;
  transition: all 0.5s;
  display: block;
}

.sign-in-button:hover {
  background-color: transparent;
  color: white;
  border: 1px solid white;
}

.account-dropdown-label {
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
}

.sub-menu-items-container-large-dev {
  background-color: #4942E4;
  border-radius: 5px;
  position: absolute;
  top: 40px;
  left: -12px;
  overflow: hidden;
  z-index: 100;
}

.sub-menu-items-container-large-dev li {
  padding-inline: 14px;
  padding-block: 8px;
  transition: 0.5s all;
}

.sub-menu-items-container-large-dev li:hover {
  background-color: #412fd4;
}


@media only screen and (max-width: 766px) {
  .navbar-logo {
    height: 40px;
  }

  .sign-in-button {
    width: 90px;
  }

  .navbar-main {
    margin-inline: 20px;
  }
}

/* @media only screen and (max-width: 400px) {
  .navbar-logo {
    height: 30px;
  }
} */

@media only screen and (max-width: 1200px) {

  .large-nav-items-container {
    gap: 30px;
  }
  }