.winnings-for-this-race {
    text-align: center;
    color: white;
    margin-top: 24px;
    margin-bottom: 14px;
}

.linked1{
    position: absolute;
    display: block;
    min-width: 65px;
    top: 0;
    left: 0;
    width: 65px;
    height: 100%;
    z-index: 9;
    background-color: transparent;
    border: none;
}
.linked2{
    position: absolute;
    display: block;
    min-width: 65px;
    top: 0;
    right: 0;
    width: 65px;
    height: 100%;
    z-index: 9;
    background-color: transparent;
    border: none;
}
.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 36px;
}

.start-button {
    width: 100px;
    color: white;
    border: none;
    font-size: 14px;
    padding-block: 5px;
    background-color: #337099;
    border-radius: 3px;
}
.cancel-button {
    width: 100px;
    color: white;
    border: none;
    font-size: 14px;
    padding-block: 5px;
    background-color: #bd0404;
    border-radius: 3px;
}

.horse-selection {
    max-width: 400px;
    margin-inline: auto;
    margin-bottom: 10px;
    position: relative;
}

.custom-horse-select-label {
    font-size: 14px !important;
    background-color: #202b52;
    color: white;
    padding-left: 7px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 32px);
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transform: translate(9px, -9px) scale(0.75);
    -moz-transform: translate(9px, -9px) scale(0.75);
    -ms-transform: translate(9px, -9px) scale(0.75);
    transform: translate(9px, -9px) scale(0.75);
    z-index: 1;
}