.package-header{
    color: #30A2FF;
    background: rgba(0, 0, 0, 0.5);
    padding-block: 16px;
}

.find-more-button {
    --slant: 0.7em;
    border: none;
    color: white;
    padding-inline: 22px;
    padding-block: 7px;
    font-weight: 500;
    font-size: 18px;
    clip-path: polygon(0 0, calc(100% - var(--slant)) 0, 100% var(--slant), 100% 100%, var(--slant) 100%, 0 calc(100% - var(--slant)));
    margin-bottom: 75px;
}

.each-package {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 6.08px 6.08px rgba(0, 0, 0, 0.5);
}

.package-detail {
    color: white;
    margin-top: 75px;
    margin-bottom: 4px;
}

.packages-container :nth-child(1) .each-package {
    background: url("../../Assets/Images/package1.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.packages-container :nth-child(2) .each-package {
    background: url("../../Assets/Images/package2.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.packages-container :nth-child(3) .each-package {
    background: url("../../Assets/Images/package3.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.packages-container :nth-child(4) .each-package {
    background: url("../../Assets/Images/package4.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.packages-container :nth-child(1) .each-package .find-more-button {
    background: #4583ff;
}
.packages-container :nth-child(2) .each-package .find-more-button {
    background-color: #47A992;
}
.packages-container :nth-child(3) .each-package .find-more-button {
    background-color: #EA906C;
}
.packages-container :nth-child(4) .each-package .find-more-button {
    background-color: #9336B4;
}

.border-bottom-pack-details {
    height: 2px;
    width: 42%;
    margin-inline: auto;
    background-color: #4583ff;
    margin-bottom: 54px;
    border-radius: 2px 2px 2px 2px;
}

.return-amount {
    color: #45CFDD;
}