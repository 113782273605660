/* ------------------------------------- */
.button-metamask {
  align-items: center;
  background: rgb(194, 116, 0);
  border: 0;
  border-radius: 5px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 12px !important;
  font-weight: 350;
  justify-content: center;
  line-height: 20px;
  max-width: 300px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  margin: 0;
  /* animation: flash 1s infinite; */

  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

.button-metamask img {
  width: 40px;
}

/* @keyframes flash {
      0% {
        background-color: blue;
      }
      50% {
        background-color: yellow;
      }
      100% {
        background-color: blue;
      }
    } */

@keyframes glowing {
  0% {
    background-color: rgb(194, 116, 0);
    -webkit-box-shadow: 0 0 3px rgb(194, 116, 0);
  }

  50% {
    background-color: rgb(194, 116, 0);
    -webkit-box-shadow: 0 0 20px rgb(194, 116, 0);
  }

  100% {
    background-color: rgb(194, 116, 0);
    -webkit-box-shadow: 0 0 3px rgb(194, 116, 0);
  }
}

.button-metamask:hover,
.button-metamask:focus {
  background-color: rgb(163, 98, 0);
  color: #ffffff;
}

.button-metamask:active {
  background-color: rgb(163, 98, 0);
  color: rgb(255, 255, 255, 0.7);
}

.button-metamask:disabled {
  cursor: not-allowed;
  background: rgb(112, 97, 60);
  color: rgba(0, 0, 0, 0.3);
}

/* ----------------------------------------- */
@media screen and (max-width: 450px) {
  .button-metamask img {
    width: 30px;
  }
}
