.payments .nav-tabs .nav-item.show .nav-link,
.payments .nav-tabs .nav-link.active {
    color: white;
    background-color: #6958BE;
    border-color: #6958BE;
}

.payments .nav-tabs .nav-link {
    color: white;
}

.payments .table-responsive {
    background-color: #272D47;
    border-radius: 10px;
    padding: 15px;
}


@media screen and (max-width: 776px) {
    .payments .nav-tabs .nav-link {
        margin-bottom: 15px;
        padding: 5px 0 !important;
    }

}