.sighupSmall {
  font-size: 12px;
  color: #a2a2a2;
}

.d-item1 {
  padding: 8px;
}

.card {
  align-items: center;
  background: #fff;
  background-color: #242435;
  border-radius: 4px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease;
  width: auto;
  padding-top: 10px;
}

.card:hover {
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}

.card-content {
  background-color: #242435;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 1px solid #e9e9eb;
  margin-top: 11px;
  min-height: 104px;
  padding: 15px 15px 10px;
  transition: all 0.2s ease;
  width: 100%;
}

.card-img {
  background-color: #fff;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 224px;
  margin: 15px 15px 0;
  margin-top: 0 !important;
  position: relative;
  transition: opacity 0.2s ease;
  width: 224px;
}

.card-img .overlay {
  background-color: #fff;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.card_hover_icon {
  align-items: center;
  justify-content: space-evenly !important;
}

.card_hover_button,
.card_icon_bg {
  background: linear-gradient(to right, #1081e8, #f32177) !important;
}

.card_hover_button {
  border: none !important;
  border-radius: 25px;
  color: #dee2e6 !important;
  font-size: 11px;
  padding: 9px 12px 6px;
}

.card_bottom_btn_main {
  justify-content: center;
}

.card_button2 {
  /* background: none; */
  border: 1px solid #dee2e6 !important;
  border-radius: 6px;
  /* color: #dee2e6 !important; */
  font-size: 0.9rem !important;
  padding: 8px 1px;
  background-color: rgb(229 113 4);
  font-weight: 600;
}

.card_button {
  background: none;
  border: 1px solid #dee2e6 !important;
  border-radius: 6px;
  color: #dee2e6 !important;
  font-size: 11px;
  padding: 9px 14px 6px;
}

.text-light .card {
  color: #ffffff !important;
  background: rgba(255, 255, 255, 0.05);
}

.card-content a {
  color: #202927;
  text-decoration: none;
}

.fontArial {
  font-family: Arial, Helvetica, sans-serif !important;
  font-style: normal;
  /* font-size: 1px; */
}

.category-wrapper {}

.savethehostage .table-bordered,
.savethehostage .table-bordered td {
  border-color: white !important;
}

.success {
  color: greenyellow;
}

.pagination-btn {
  margin-top: 16px;
}

.pagination-btn button {
  color: white;
  background-color: darkslategrey;
  margin-top: 8px;
}

.pagination-btn button.Mui-selected {
  color: white;
  background-color: red !important;
}

.pagination-btn .MuiPaginationItem-sizeMedium {
  color: white !important;
}

.pagination-btn nav ul {
  justify-content: center;
}

.widthDetailsInput {
  width: 75%;
}

.diffrentBack1 {
  /* background-color: #413d42; */
  width: 90%;
  padding: 12px;
}

.diffBack2 {
  /* background-color: #45002d; */
  width: 90%;
  padding: 12px;
}

.details-page .landing-button {
  width: 75%;
}

.details-page .landing-button button {
  width: 100%;
  max-width: initial;
  padding: 10px 0;
}

.slider-nft {
  width: 50%;
  margin: 0 auto;
}

.nft-card {
  min-height: 90px;
}

.handleBarcode {
  position: absolute;
  right: 0rem;
  bottom: 0;
  justify-self: end;
  width: 20% !important;
  height: auto !important;
}

.img_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding: 2rem 0;
}

.video_div {
  width: 100%;
  height: 400px;
  margin-top: -124px;
}

.details_video {
  width: 100%;
  height: 100%;
}

.payTexts {
  margin-bottom: 0;
}

.paynowPaidButton {
  background-color: #0d6efd;
  color: white;
  width: 300px;
  padding: 6px 8px;
  border-radius: 6px;
  border: none;
}

.pricePayNow {
  color: #0d6efd;
}

.paynowBTNC {
  padding: 2px 6px;
  margin-left: 5px;
  border-radius: 10px;
  background: #0d6efd;
  margin-bottom: 1rem;
  border: none;
}

.paynowLaterPaidButton {
  background-color: transparent;
  border: 2px solid #0d6efd;
  color: #0d6efd;
  width: 300px;
  padding: 6px 8px;
  border-radius: 6px;
}

.companyDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttonDiv {
  margin-bottom: -0.1rem;
  padding-bottom: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
}

.nft-watermark {
  position: relative;
  opacity: 0.9;
  right: 3.3rem;
  top: 15px;
  justify-self: end;
  width: 10% !important;
  height: auto !important;
  visibility: hidden;
}

.nft-watermark3 {
  position: absolute;
  opacity: 0.9;
  right: 0rem;
  top: 50%;
  transform: translateY(-50%);
  justify-self: end;
  width: 10% !important;
  height: auto !important;
}

.nft-watermark2 {
  position: relative;
  opacity: 0.9;
  left: 12.2rem;
  top: 6rem;
  justify-self: end;
  width: 14% !important;
  height: auto !important;
}

.fontExtand {
  font-size: 1rem;
}

.spaceIssue p {
  /* padding-bottom: -130px; */
  margin-bottom: 0px;
}

.handlePosition {
  position: relative;
  top: -7px;
  left: -25px;
}

.priceDropdown {
  display: flex;
  align-items: center;
}

.viewall {
  text-align: center;
  text-decoration: none;
  padding-top: 100px;
  color: #0d6efd;
  font-weight: normal;
}

.certificateCelebrity {
  margin-bottom: 0rem;
  height: 83.5%;
}

.paynow_disable_button {
  width: 75%;
  /* border-radius: 10px; */
  cursor: auto;
}

.paynow_enable_button {
  width: 75%;
  /* border-radius: 10px; */
  cursor: pointer;
}

.free-nft-code-input::placeholder {
  color: white !important;
}

.deteilsPageImage {
  width: 100%;
  /* height: 100%; */
}

.button_dtl {
  background: #c27400 !important;
  font-size: 14px !important;
  font-weight: 500;
  padding: 15px 26px 13px 12px;
  width: 75% !important;
}

.nft-info-button {
  width: fit-content;
  margin-inline: auto;
  margin-top: 12px;
}


.nft-info-container {
  position: absolute;
  min-width: max-content;
  background-color: rgb(21, 64, 127);
  padding-inline: 10px;
  padding-block: 6px;
  font-size: 14px;
  border-radius: 6px;
  z-index: 10;
  left: -120px;
}

.nft-info-container p{
  margin-bottom: 0px;
  color: white;
}


@media only screen and (max-width: 769px) {
  .button_dtl {
    font-size: 11px !important;
    font-weight: 500;
    width: 100% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .video_div {
    width: 100%;
    height: 400px;
    margin-top: -100px;
  }
}

@media only screen and (max-width: 979px) {
  .video_div {
    width: 100%;
    height: 400px;
    margin-top: -72px;
  }
}

@media only screen and (max-width: 767px) {
  .video_div {
    width: 100%;
    height: 400px;
    margin-top: -110px;
    margin-bottom: 50px;
  }

  .d-item1 {
    display: flex !important;
    justify-content: center;
  }

  .deteilsPageImage {

    /* height: 100%; */
  }
}

@media only screen and (max-width: 700px) {
  .video_div {
    width: 100%;
    height: 400px;
    margin-top: 0px;
    margin-bottom: 50px;
  }

  .pagination-btn nav {
    height: auto !important;
  }
}

@media only screen and (max-width: 600px) {
  .paynow_disable_button {
    width: 75%;
    cursor: auto;
  }
.nft-info-container{
  left: -126px;
}
  .diffrentBack1 {
    /* background-color: #413d42; */
    width: 100%;
    padding: 12px;
  }

  .diffBack2 {
    /* background-color: #45002d; */
    width: 100%;
    padding: 12px;
  }

  .widthDetailsInput {
    width: 100%;
  }

  .paynow_enable_button {
    width: 75%;
    cursor: pointer;
  }

  .video_div {
    width: 100%;
    height: 400px;
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 376px) {
  .paynow_disable_button {
    width: 75%;
    cursor: auto;
  }

  .widthDetailsInput {
    width: 100%;
  }

  .paynow_enable_button {
    width: 75%;
    cursor: pointer;
  }


  .certificateCelebrity {
    height: 100%;
    margin-bottom: 0;
  }

  .nft-watermark {
    position: relative;
    visibility: hidden;

    right: -3.1rem;
    top: 10px;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }

  .nft-watermark2 {
    position: relative;
    opacity: 0.9;
    left: 15.5rem;
    top: 6.5rem;
    justify-self: end;
    width: 11% !important;
    height: auto !important;
  }



  .marginPhone {
    margin-top: 1rem;
  }
}

/* @media only screen and (min-width:321px) and (max-width: 335px) {
    .handleBarcode {
        position: relative;
        left: 4.6rem;
        top: 107px;
        justify-self: end;
        width: 20% !important;
        height: auto !important;
    }

    .nft-watermark {
        position: relative;
        right: -3.2rem;
        top: 10px;
        justify-self: end;
        width: 10% !important;
        height: auto !important;
    }

} */

@media only screen and (min-width: 377px) and (max-width: 425px) {


  .certificateCelebrity {
    height: 100%;
    margin-bottom: 0;
  }

  .widthDetailsInput {
    width: 100%;
  }

  .marginPhone {
    margin-top: 1rem;
  }

  .nft-watermark {
    position: relative;
    right: -2.9rem;
    top: 10px;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
    visibility: hidden;
  }

  .nft-watermark2 {
    position: relative;
    opacity: 0.9;
    left: 16rem;
    top: 6.5rem;
    justify-self: end;
    width: 11% !important;
    height: auto !important;
  }


}

/* @media only screen and (min-width:376px) and (max-width: 425px) {
    .handleBarcode {
        position: relative;
        left: 2.5rem;
        top: 132px;
        justify-self: end;
        width: 20% !important;
        height: auto !important;
    }

    .nft-watermark {
        position: relative;

        right: -1rem;
        top: 10px;
        justify-self: end;
        width: 10% !important;
        height: auto !important;
    }
} */

@media only screen and (min-width: 426px) and (max-width: 500px) {


  .certificateCelebrity {
    height: 100%;
    margin-bottom: 0;
  }

  .widthDetailsInput {
    width: 100%;
  }

  .nft-watermark {
    position: relative;
    visibility: hidden;

    right: -2.5rem;
    top: 10px;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }

  .marginPhone {
    margin-top: 1rem;
  }

  .nft-watermark2 {
    position: relative;
    opacity: 0.9;
    left: 17.5rem;
    top: 6.5rem;
    justify-self: end;
    width: 11% !important;
    height: auto !important;
  }


}

@media only screen and (min-width: 501px) and (max-width: 699px) {


  .widthDetailsInput {
    width: 100%;
  }

  .certificateCelebrity {
    height: 100%;
    margin-bottom: 0;
  }

  .marginPhone {
    margin-top: 1rem;
  }

  .nft-watermark {
    position: relative;
    visibility: hidden;

    right: -1.5rem;
    top: 10px;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }

  .nft-watermark2 {
    position: relative;
    opacity: 0.9;
    left: 17.5rem;
    top: 6.5rem;
    justify-self: end;
    width: 11% !important;
    height: auto !important;
  }


}

@media only screen and (min-width: 700px) and (max-width: 767px) {


  .nft-watermark {
    position: relative;
    visibility: hidden;

    left: 26.5rem;
    top: -18rem;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }

  .nft-watermark2 {
    position: relative;
    opacity: 0.9;
    left: 17.5rem;
    top: 6.5rem;
    justify-self: end;
    width: 11% !important;
    height: auto !important;
  }


}

/* @media only screen and (min-width:768px) and (max-width: 979px) {
    .handleBarcode {
        position: relative;
        left: 1rem;
        top: 10.5rem;

    }

    .nft-watermark {
        position: relative;

        right: -1rem;
        top: 10px;
        justify-self: end;
        width: 10% !important;
        height: auto !important;
    }
} */

.icon_love_Dtl_box {
  align-items: center;
  background: #2d384f;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 42px;
  justify-content: center;
  /* left: 3.5rem;   */
  /* margin-right: 1.6rem; */
  padding: 4px;
  position: relative;
  width: 48px;
  color: white;
  margin-left: auto;
}

.check-login {
  background: transparent;
  border: none;
  color: #fff;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {


  .nft-watermark {
    position: relative;
    visibility: hidden;

    right: 2.1rem;
    top: 10px;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }

  .nft-watermark2 {
    position: relative;
    opacity: 0.9;
    left: 17.5rem;
    top: 6.5rem;
    justify-self: end;
    width: 11% !important;
    height: auto !important;
  }


}

@media only screen and (min-width: 992px) and (max-width: 1184px) {


  .nft-watermark {
    position: relative;
    visibility: hidden;

    right: 2rem;
    top: 10px;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }

  .nft-watermark2 {
    position: relative;
    opacity: 0.9;
    left: 17.5rem;
    top: 6.5rem;
    justify-self: end;
    width: 11% !important;
    height: auto !important;
  }

}

@media only screen and (min-width: 1185px) and (max-width: 1399px) {}

@media only screen and (max-width: 567px) {
  .slider-nft {
    width: 100%;
  }

  .nft-card {
    min-height: 70px;
  }

  .details-page .landing-button {
    width: 100%;
  }
}