.outletContainer {
    min-height: calc(100vh - 64px);
  }
  .imgProduct {
    width: 60px;
  
  }
  .sideBar {
    background-color: #272d47;
    /* min-height: 100%; */
    text-align: center;
    padding-bottom: 20px;
    min-height: 100vh;
  }
  
  .svgIconColor svg {
    color: white !important;
  }
  
  .dashLogo {
    margin-top: -4.5rem;
    width: 60px;
    padding: 5px;
  }
  
  .dashboardTitlehidden {
    visibility: hidden;
  }
  
  .dashboardNav {
    background-color: #272d47;
    width: 100%;
    height: 100%;
  }
  
  .menuDiv {
    text-align: start;
    color: white;
    margin-left: 1rem;
    margin-top: 1.5rem;
  }
  
  .menuTxt {
    color: rgba(255, 255, 255, 0.65);
  }
  
  .menuDiv .dashboardMenu {
    text-decoration: none;
    display: inline-block;
    color: rgba(255, 255, 255, 0.65);
    fill: rgba(255, 255, 255, 0.65);
    border-radius: 20px;
    width: 226px;
    height: 42px;
    padding-left: 2px;
    padding-top: 2px;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
  }
  
  .menuDiv i {
    margin-right: 1rem;
  }
  
  .navIconAdmin {
    background: #2f3655;
    box-shadow: 0px 2px 3px rgb(4 4 7 / 10%);
    border: 1px solid rgba(255, 255, 255, 0.04);
    display: inline-block;
    width: 37.5px;
    height: 37.5px;
    padding: 8px;
    border-radius: 50px;
    margin-bottom: 1rem;
    margin-right: 12px;
  }
  
  .navIconAdmins {
    background: #2f3655;
    box-shadow: 0px 2px 3px rgb(4 4 7 / 10%);
    border: 1px solid rgba(255, 255, 255, 0.04);
    display: inline-block;
    width: 37.5px;
    height: 37.5px;
    padding: 8px;
    border-radius: 50px;
    margin-bottom: 1rem;
    /* margin-right: 12px; */
  }
  
  .navIconAdmins1 {
    background: #2f3655;
    box-shadow: 0px 2px 3px rgb(4 4 7 / 10%);
    border: 1px solid rgba(255, 255, 255, 0.04);
    display: inline-block;
    width: 37.5px;
    height: 37.5px;
    padding: 8px;
    border-radius: 50px;
    margin-bottom: 1rem;
    /* margin-right: 12px; */
  }
  
  .navIconAdmin i {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    color: rgba(255, 255, 255, 0.65);
    fill: rgba(255, 255, 255, 0.65);
    margin-left: -0rem;
    margin-top: -0.2rem;
  }
  
  .navIconAdmins i {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    color: rgba(255, 255, 255, 0.65);
    fill: rgba(255, 255, 255, 0.65);
    margin-left: -0rem;
    margin-top: -0.2rem;
  }
  
  .navIconAdmins1 i {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    color: rgba(228, 76, 76, 0.65);
    fill: rgba(230, 85, 85, 0.65);
    margin-left: -0rem;
    margin-top: -0.2rem;
  }
  
  .nav-link {
    color: rgba(255, 255, 255, 0.65);
  }
  
  .menuTxt:hover {
    color: rgb(245, 244, 244);
  }
  
  .menuTxt:active {
    color: rgb(240, 237, 237);
  }
  
  .menuTxt:focus {
    color: #fff;
    font-weight: bold;
  }
  
  .imgDashDiv img {
    width: 40px;
    border-radius: 50%;
    margin-left: 54rem;
  }
  
  .dashboardTopBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .adminProfile {
    background-color: #272d47;
    width: 20%;
    position: absolute;
    margin-left: 61rem;
    margin-top: 2rem;
    will-change: transform;
    transform: translate3d(-147px, 46px, 0px);
    border: 1px solid rgba(255, 255, 255, 0.04);
    box-shadow: 0 10px 15px rgb(15 17 29 / 90%);
    color: #d5dae2;
    padding: 20px;
    border-radius: 20px;
    visibility: hidden;
    opacity: 0;
  }
  
  .adminProfile.active {
    visibility: visible;
    opacity: 1;
    margin-top: 1rem;
    transition: all 0.5s ease-in-out;
  }
  
  .adminProfile a {
    color: #d5dae2;
    text-decoration: none;
    display: inline-block;
  }
  
  .logoutBtn {
    cursor: pointer;
  }
  
  .dashboardSubmenu {
    display: flex;
  }
  
  .dashboardSubmenu1 {
    display: flex;
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  
  .mintMenu {
    margin-left: 12px;
    font-size: 0.95rem;
  }
  
  .mintMenu1 {
    margin-left: 12px;
    font-size: 0.95rem;
    color: rgb(230, 75, 75);
  }
  
  .subMenu {
    margin-right: -4rem;
  }
  
  /* titles registered  */
  .titlesregistered {
    height: 100vh;
  }
  
  .titleMinted {
    height: auto;
  }

  .dashboard-title {
    font-family: 'Khula', sans-serif;
    margin-top: 0px;
    margin-bottom: 24px;
    color: #fff;
    font-size: 24px;
    line-height: 48px;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .circle-title {
    font-family: 'Khula', sans-serif;
    margin-top: 0px;
    color: #fff;
    font-size: 24px;
    line-height: 40px;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .circle-title-3rd-child {
    font-family: 'Khula', sans-serif;
    margin-top: 0px;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  
  .dashboardDiv {
    background-color: #1a1c33;
    height: 100%;
    width: 100%;
  }
  
  .dashboardTxt {
    color: white;
    line-height: 10px;
  }
  
  .dashboardTxt>p,
  .dashboardTxt>h2 {
    color: #ffffff !important;
  }
  
  .dashboardTxt p {
    font-size: 0.9rem;
  }
  
  .cardDash {
    background-color: #272d47 !important;
    cursor: pointer;
    min-height: 140px;
  }
  
  svg {
    /* color: black; */
  }
  
  .demoP {
    visibility: hidden;
  }
  
  .coinsIcon {
    font-size: 3rem;
    margin-right: 0rem;
    margin-bottom: -0.8rem;
  }
  
  .parcentage_text {
    line-height: 12px;
  }
  
  .dashboardTxt h6 {
    font-size: 0.9rem;
    text-align: start;
    width: 100%;
  }
  
  .orderIcon {
    border-radius: 100% !important;
    font-size: 50px !important;
    padding: 10px;
  }
  
  .setBg1 {
    border: 1px solid rgb(249, 117, 9);
    background-color: rgb(249, 117, 9);
  }
  
  .setBg2 {
    border: 1px solid rgb(65, 33, 243);
    background-color: rgb(78, 37, 243);
  }
  
  .setBg3 {
    border: 1px solid rgb(36, 175, 206);
    background-color: rgb(36, 167, 197);
  }
  
  .setBg4 {
    border: 1px solid rgb(3, 137, 3);
    background-color: rgb(3, 137, 3);
  }
  
  .copyrightAdmin {
    background-color: #272D47;
    padding: 10px;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
  
  @media only screen and (max-width: 994px) {
    .dashboardDiv {
      background-color: #1a1c33;
      height: auto;
      background-size: cover;
    }
  
    .coinsIcon {
      padding-top: 0px;
    }
  }
  
  @media only screen and (max-width: 2100px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 81rem;
    }
  
    .adminProfile {
      background-color: #272d47;
      width: 18%;
      position: absolute;
      margin-left: 80rem;
    }
  }
  
  @media only screen and (max-width: 1900px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 79rem;
    }
  
    .adminProfile {
      background-color: #272d47;
      width: 21%;
      position: absolute;
      margin-left: 78rem;
    }
  }
  
  @media only screen and (max-width: 1700px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 69.8rem;
    }
  
    .adminProfile {
      background-color: #272d47;
      width: 21%;
      position: absolute;
      margin-left: 73rem;
    }
  }
  
  @media only screen and (max-width: 1600px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 67.5rem;
    }
  
    .adminProfile {
      background-color: #272d47;
      width: 21%;
      position: absolute;
      margin-left: 71rem;
    }
  }
  
  @media only screen and (max-width: 1400px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 55rem;
    }
  
    .adminProfile {
      background-color: #272d47;
      width: 20%;
      position: absolute;
      margin-left: 62rem;
    }
  }
  
  @media only screen and (max-width: 1294px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 49rem;
    }
  
    .adminProfile {
      background-color: #272d47;
      width: 30%;
      position: absolute;
      margin-left: 50rem;
    }
  }
  
  @media only screen and (max-width: 1054px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 34rem;
    }
  
    .adminProfile {
      background-color: #272d47;
      width: 40%;
      position: absolute;
      margin-left: 35rem;
    }
  }
  
  @media only screen and (max-width: 964px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 28.2rem;
    }
  
    .adminProfile {
      background-color: #272d47;
      width: 40%;
      position: absolute;
      margin-left: 32rem;
    }
  }
  
  @media only screen and (max-width: 854px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 22.5rem;
    }
  
    .adminProfile {
      background-color: #272d47;
      width: 40%;
      position: absolute;
      margin-left: 29rem;
    }
  }
  
  @media only screen and (max-width: 774px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 19rem;
    }
  
    .adminProfile {
      background-color: #272d47;
      width: 40%;
      position: absolute;
      margin-left: 26rem;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 18rem;
    }
  }
  
  @media only screen and (max-width: 450px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 9rem;
    }
  
    .adminProfile {
      background-color: #272d47;
      width: 70%;
      position: absolute;
      margin-left: 15rem;
    }
  }
  
  @media only screen and (max-width: 400px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 8.5rem;
    }
  
    .adminProfile {
      background-color: #272d47;
      width: 70%;
      position: absolute;
      margin-left: 15rem;
    }
  }
  
  @media only screen and (max-width: 365px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 7.6rem;
    }
  
    .adminProfile {
      background-color: #272d47;
      width: 70%;
      position: absolute;
      margin-left: 15rem;
    }
  }
  
  @media only screen and (max-width: 290px) {
    .imgDashDiv img {
      width: 42px;
      border-radius: 50%;
      margin-left: 3rem;
    }
  
    .adminProfile {
      background-color: #272d47;
      width: 70%;
      position: absolute;
      margin-left: 13rem;
    }
  }
  