.nfts-container {
  margin-block: 50px;
}

.each-nft {
  background: #1b4761;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
}

.each-nft-name {
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  border-radius: 0px 0px 5px 5px;
  padding-bottom: 7px;
  padding-top: 7px;
  /* font-family: "Inter"; */
  font-weight: 500;
}

.buy-nft-button {
  background-color: #b57f0f;
  border-radius: 6px;
  /* font-family: "Inter"; */
  border: none;
  color: white;
  width: 100%;
  padding-block: 10px;
  margin-top: 8px;
}

.view-all-nft-button {
  border: none;
  border-radius: 5px;
  background: #4583ff;
  padding-inline: 30px;
  padding-block: 6px;
  /* font-family: "Inter"; */
  font-weight: 500;
  font-size: 18px;
  color: white;
  text-align: center;
  display: block;
  margin-inline: auto;
  margin-top: 26px;
}
