.network-chart::-webkit-scrollbar {
    width: 2px;
    height: 10px;
  }

  /* Track */
  .network-chart::-webkit-scrollbar-track {
    background: #1493af;
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
 
  /* Handle */
  .network-chart::-webkit-scrollbar-thumb {
    background: #97c3cf;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .network-chart::-webkit-scrollbar-thumb:hover {
    background: #555;
  }