.display-sm {
    display: none;
}


@media only screen and (max-width: 769px) {
    .display-lg {
        display: none;
    }

    .bw {
        width: 51%;
        margin: 0 auto;
    }
}