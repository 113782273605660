.footer-container {
  background-color: #332d6b;
  /* padding-inline: 92px; */
  color: white;
  padding-top: 50px;
  /* padding-bottom: 40px; */
  margin-top: 22px;
}

.footer-first-part {
  /* padding-right: 30px; */
}

.title-bottom-border {
  height: 3px;
  background-color: #4677c0;
  margin-bottom: 25px;
  margin-top: 12px;
}

.footer-third-part p a {
  text-decoration: none;
  color: white;
}

.footer-third-part p {
  display: flex;
  gap: 20px;
  align-items: center;
}

.footer-third-part p img {
  width: 21px;
}

.quick-links-container a {
  text-decoration: none;
  color: white;

}

.footer-copy-right-text {
  font-size: 20px;
}

@media only screen and (max-width: 750px) {
  .footer-container {
    padding-top: 26px;
  }

  .footer-copy-right-text {
    font-size: 17px;
    text-align: center;
  }
}

@media only screen and (max-width: 650px) {
  /* .footer-container {
    padding-inline: 40px;
  } */
}