.handleTheLoginBody {
  /* background-color: #f7f6f6; */
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forCard {
  background-color: #d1d1d1 !important;
  transition: 0.3s ease-out !important;
}

.forCard:hover {
  box-shadow: 0px 9px 10px -6px #f2f2f2;
}


/* CSS */
.button-34 {
  background: #f74545;
  border-radius: 10px !important;
  box-shadow: #dd5555 0 10px 20px -10px;
  box-sizing: border-box;
  color: #ffffff !important;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}


.submit_OTP_btn {
  width: 175px !important;
  background-color: #5E5DF0 !important;
  box-shadow: #3939cf 0 10px 20px -10px !important;
  text-transform: uppercase;
}

.submit_OTP_btn:hover {
  background-color: #3b3ba6 !important;
}

.resend_OTP_btn {
  width: 175px !important;
  background-color: blueviolet !important;
  box-shadow: rgb(102, 30, 170) 0 10px 20px -10px !important;
  text-transform: uppercase;
}

.resend_OTP_btn:hover {
  background-color: rgb(97, 32, 157) !important;
}

@media screen and (max-width: 840px) {
  .handleTheLoginBody {
    height: auto;
    padding: 60px 0;
  }

  .forCard {
    margin-top: 20px;
    width: 100% !important;
  }
}