.payment-method-container {
  background: linear-gradient(90deg, #332d6b 0%, #232b59 100%);
  padding-bottom: 80px;
  padding-top: 60px;
}

.list-group {
  /* background: linear-gradient(90deg, #332d6b 0%, #232b59 100%) !important; */
  --bs-list-group-bg: #332d6b !important;
}

.pay-button {
  --slant: 0.7em;
  border: none;
  background: #4583ff;
  color: white;
  padding-inline: 30px;
  padding-block: 6px;
  /* font-family: "Inter"; */
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
  clip-path: polygon(
    0 0,
    calc(100% - var(--slant)) 0,
    100% var(--slant),
    100% 100%,
    var(--slant) 100%,
    0 calc(100% - var(--slant))
  );
}

.enter-payment-code {
  border-radius: 4px;
  padding-left: 30px;
  padding-block: 8px;
  border: none;
  width: 100%;
}
