.modal-content {
  background: linear-gradient(to right bottom, #1b1d22, #2a2e35);
  color: #fff;
}

.closeD {
  text-align: right;
}

.iconClose {
  background: none !important;
  border: none !important;
  font-size: 25px !important;
  height: 40px;
  position: absolute;
  right: 5px;
  top: 2px;
  width: 26px;
}

.banner-button2 {
  background-image: linear-gradient(90deg, #ff512f 0, #db1528 51%, #ff512f);
  background-size: 200% auto;
  border: none;
  color: #fff;
  font-weight: 700;
  margin: 10px;
  padding: 8px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  border-radius: 10px;
  font-size: 14px;
}
