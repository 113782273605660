.image {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.page-title {
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  /* background-image: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  ); */

  /* background-image: linear-gradient(
    90deg,
    rgba(255, 68, 0, 1) 0%,
    rgba(255, 76, 0, 1) 50%,
    rgba(251, 237, 16, 1) 100%
  ); */

  background: linear-gradient(90deg,
      rgba(255, 124, 0, 1) 0%,
      rgba(255, 76, 0, 1) 0%,
      rgba(251, 249, 16, 1) 100%);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;
  text-transform: uppercase;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.loaders {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #db3450;
  /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.close-date {
  font-size: 22px !important;
  background: linear-gradient(90deg, #ec0321, #d60257, #b303a6, #a502ce);
}


.network-text{
  background: linear-gradient(
    to right, 
    hsl(98 100% 62%), 
    hsl(204 100% 59%)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}