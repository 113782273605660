body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #202b52;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* swal */
.modal_class_success {
  background-color: #2d313a !important;
  color: white !important;
}

.swal-footer {
  display: flex;
  justify-content: center !important;
}

.modal_class_success .swal-icon::before {
  background-color: transparent;
}

.modal_class_success .swal-icon::after {
  background-color: transparent;
}

.swal-content {
  padding: 0 20px;
  margin-top: 9px;
  font-size: 13px;
  overflow-wrap: break-word !important;
  color: white !important;
}

.swal-icon--success__hide-corners {
  display: none;
}

.swal-title {
  color: white !important;
}

.swal-text {
  color: white !important;
  text-align: center !important;
}

.swal-modal {
  background-color: #2d313a !important;
}