.table-container{
    border-radius: 5px;
    overflow-x: auto;
}

.transaction-table {
    width: 100%;
    background-color: white;
    border-radius: 5px;
    /* text-align: center; */
    /* border-collapse: collapse; */
}

.transaction-table th + th{
    border-left: 1px solid gray;
}

.transaction-table th {
    border-bottom: 1px solid gray;
    padding-inline: 8px;
    padding-block: 18px;
}

.transaction-table td + td {
    border-left: 1px solid gray; 
}

.transaction-table td {
    padding-block: 14px;
    padding-inline: 8px;
}


.view-button {
    border: none;
    font-size: 14px;
    color: white;
    background: linear-gradient(180deg, #3080AC 24.81%, #08243B 118.18%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding-inline: 10px;
    padding-block: 6px;
    padding-bottom: 8px;
}

