.text-gradient {
  background: linear-gradient(45deg, #f32177, #1081e8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.small-border {
  width: 50px;
  height: 2px;
  background: rgba(0, 0, 0, 0.5);
  border-left: none;
  border-right: none;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
  background: linear-gradient(45deg, #f32177, #1081e8);
}

.select-win-btn {
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translate(-50%);
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  /* background-color: #1081e8; */
  width: 20%;
}

.galarace-btn {
  position: absolute !important;
  background: linear-gradient(45deg, #f32177, #1081e8) !important;
  top: 50%;
  left: 50%;
  width: max-content;
  padding: 10px 25px !important;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.win-busd-button {
  background-color: #C38154 !important;
  color: white !important;
  width: max-content;
  padding: 10px 25px !important;
  z-index: 9;
}

.win-grt-button {
  background-color: rgb(17, 106, 123);
  position: absolute !important;
  top: 50%;
  left: 50%;
  width: max-content;
  padding: 10px 25px !important;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.win-busd-button-container {
  position: absolute !important;
  top: 50%;
  left: 50%;
  width: max-content;
  transform: translate(-50%, -50%);
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.money-button1 {
  background-color: #000 !important;
  color: #daa520 !important;
  /* margin-right: 20px !important; */
  padding: 10px 15px !important;
}

/* .money-button2 {
  background-color: #f7f7f7 !important;
  color: #000 !important;
  padding: 8px 15px !important;
} */

.money-button2 {
  width: 320px !important;
  padding: 10px 0px !important;
  margin-right: 0px !important;
}

.money-img {
  height: 35px;
  margin-right: 10px;
  width: 35px;
}

.back-button {
  width: 265px;
  border-radius: 4px;
}

.all-race-rep-button {
  width: 265px;
  border-radius: 4px;
}

.win-busd-swal {
  max-width: 328px !important;
}

@media screen and (max-width: 567px) {
  .win-btns {
    display: flex;
    flex-direction: column;
  }

  .money-button1,
  .money-button2 {
    /* margin-bottom: 10px !important; */
    margin-right: 0 !important;
    padding: 10px 0 !important;
    width: 265px !important;
  }

  .select-win-btn {
    width: 63%;
  }
}