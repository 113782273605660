/* .home-container {
  background-color: #10102a;
} */

.large-device-banner-container {
  /* padding-block: 22px; */
}

.large-device-banner {
  /* min-height: 100vh; */
  position: relative;
}

.large-device-banner-img {
  /* height: 100%; */
  /* min-height: 90vh; */
  /* min-height: 100%; */
  width: 100%;
}

.large-banner-hamburger {
  border: none;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  right: 37px;
  top: 4%;
  padding-block: 4px;
}


/* lading membership status for large */
.landing-large-membership-status {
  position: absolute;
  top: 35%;
  left: 14%;
  width: 258px;
}

.landing-large-membership-status .membership-status {
  font-size: 18px;
}

.landing-large-membership-status .star-container {
  /* width: 23px !important; */
}

.landing-large-membership-status .become-premium {
  /* font-size: 10px !important;
  line-height: initial; */
}

.landing-large-membership-status .get-all-stars {
  font-size: 14px;
}


/* lading membership status for small */
.landing-small-membership-status {
  position: absolute;
  top: 25%;
  left: 17%;
  width: 140px;
}

.landing-small-membership-status .membership-status-box .membership-status {
  /* font-size: 10px !important;
  line-height: initial; */
}

.landing-small-membership-status .star-container {
  /* width: 23px !important; */
}

.landing-small-membership-status .become-premium {
  /* font-size: 10px !important;
  line-height: initial; */
}

.landing-small-membership-status .get-all-stars {
  /* font-size: 10px !important;
  line-height: initial; */
}

.small-device-banner-container {
  display: none;
  position: relative;
  padding-block: 20px;
  /* padding: 20px; */
  /* padding-inline: 12px; */
}

.small-device-banner {
  max-width: 100%;
}

.small-device-banner-img {
  width: 100%;
}

.small-banner-hamburger {
  border: none;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  top: 7%;
  right: 4%;
  padding-block: 3px;
  padding-top: 1px;
  padding-inline: 4px;
}

/* NFTS */
.buy-nft-button-in-large {
  position: absolute;
  bottom: 10%;
  right: 10px;
  border: none;
  padding-inline: 12px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
  background: green;
  color: white;
}

/* OFF CANVAS */
.menu-offcanvas {
  background: linear-gradient(180deg, #1d2746 0%, #0b0b17 100%);
}

.menu-offcanvas-body {
  padding-left: 40px;
}

.menu-offcanvas-body .each-menu-item {
  list-style: none;
  margin-bottom: 22px;
}

.menu-offcanvas-body .each-menu-item a {
  text-decoration: none;
  color: white;
}

.nfts-in-small {
  display: none;
}

.mySwiper {
  width: 100%;
}

/* INVEST ON NFTs */
.invest-on-nft-container {
  /* margin-block: 30px; */
  /* padding-bottom: 16px; */
  padding: 40px 0;
}

.invest-on-nft-text-part {
  color: white;
  /* padding-top: 45px; */
  /* padding-block: 45px; */
}

.invest-on-nft-text-part h1 {
  font-size: 70px;
  /* font-family: "Inter"; */
  margin-bottom: 20px;
}

.invest-on-nft-text-part p {
  /* font-family: "Inter"; */
  /* font-weight: 500; */
  font-size: 20px;
}

.invest-on-nft-button {
  --slant: 0.7em;
  /* control the slanted corners */

  border: none;
  background: #4583ff;
  padding-inline: 30px;
  padding-block: 6px;
  /* font-family: "Inter"; */
  font-weight: 500;
  font-size: 18px;
  margin-top: 32px;

  clip-path: polygon(0 0,
      calc(100% - var(--slant)) 0,
      100% var(--slant),
      100% 100%,
      var(--slant) 100%,
      0 calc(100% - var(--slant)));
}

.gradient-bg-for-sec {
  background: linear-gradient(90deg, #332d6b 0%, #232b59 100%);
}

@media only screen and (max-width: 951px) {
  /* .large-device-banner-container {
    padding-inline: 72px;
  } */
}

@media only screen and (max-width: 767px) {
  .large-device-banner-container {
    display: none;
  }

  .small-device-banner-container {
    display: block;
  }

  .padding {
    /* padding: 0px 33px; */
    margin-top: 10px;
    text-align: center;
  }

  .invest-on-nft-container {
    /* margin-block: 30px; */
    /* padding-bottom: 16px; */
    padding: 25px 0;
  }
}

@media only screen and (max-width: 700px) {
  .invest-on-nft-text-part h1 {
    font-size: 45px;
  }

  /* .large-device-banner-container {
    padding-inline: 20px;
  } */
}

@media only screen and (max-width: 700px) {

  /* NFTs */
  .nfts-in-large {
    display: none;
  }

  .nfts-in-small {
    display: block;
  }
}