/* .handleTheLoginBody {
 background-color: #000a17;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.emailverifymodal {
  width: 480px;
}

.logindiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputBackground {
  background-color: rgb(15, 20, 22);
  color: white;
  border: 1px solid rgb(88, 88, 88);
}

.handleLogInButton {
  background: linear-gradient(to right,
      var(--secondary-color) 0%,
      var(--secondary-color-2) 100%);
  border: none;
}

.forFalseHeader {
  width: 100%;
  height: 0px;
  background-color: #717db33f;
}

.handleLogInButton:hover {
  background: linear-gradient(to right,
      var(--secondary-color) 0%,
      var(--secondary-color-2) 100%) !important;
  border: none;
}

.handleLogoLogin {
  width: 80px;
  margin-top: -20px;
}

.forCard {
  width: 50%;
  background-color: #ffffffd6 !important;
  transition: 0.3s ease-out !important;
}

.forCard:hover {
  box-shadow: 0px 9px 10px -6px #483d8b85;
}

.handleSendBtn {
  background-color: #6958be;
  border: none;
}

.handleSendBtn:hover {
  background-color: #6958be !important;
  border: none;
}

.forgetPass {
  color: #28343e !important;
}

.Forgot {
  color: rgb(15, 20, 22);
}

/* CSS */
.button-34 {
  background: #f74545;
  border-radius: 10px !important;
  box-shadow: #dd5555 0 10px 20px -10px;
  box-sizing: border-box;
  color: #ffffff !important;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

.submit_OTP_btn {
  width: 175px !important;
  background-color: #5e5df0 !important;
  box-shadow: #3939cf 0 10px 20px -10px !important;
  text-transform: uppercase;
}

.submit_OTP_btn:hover {
  background-color: #3b3ba6 !important;
}

.resend_OTP_btn {
  width: 175px !important;
  background-color: blueviolet !important;
  box-shadow: rgb(102, 30, 170) 0 10px 20px -10px !important;
  text-transform: uppercase;
}

.resend_OTP_btn:hover {
  background-color: rgb(97, 32, 157) !important;
}

/* .swal-with-heigh-index {
  z-index: 1400 !important;
} */

.verify-email-sender-button:disabled {
  background-color: rgb(151, 145, 145) !important;
}

@media screen and (max-width: 840px) {


  .forCard {
    background-color: #000a17;
    margin-top: 20px;
    /* margin-bottom: 50px; */
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .emailverifymodal {
    width: 400px;
  }
}