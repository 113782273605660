.opppertunities-container {
  background: url("../../../Assets//Images//opportunities\ image.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 687px; */
  background-position: center;
  color: white;
  text-align: center;
  padding-inline: 90px;
  padding-block: 105px;
  border-radius: 45px;
}

.opppertunities-container h3 {
  /* font-family: "Inter"; */
  margin-bottom: 15px;
}

.opppertunities-container p {
  /* font-family: "Inter"; */
  /* font-weight: 500; */
  font-size: 18px;
  margin-bottom: 32px;
}

.invest-button {
  --slant: 0.7em; /* control the slanted corners */
  border: none;
  background: #4583ff;
  padding-inline: 30px;
  padding-block: 6px;
  /* font-family: "Inter"; */
  font-weight: 500;
  font-size: 18px;

  clip-path: polygon(
    0 0,
    calc(100% - var(--slant)) 0,
    100% var(--slant),
    100% 100%,
    var(--slant) 100%,
    0 calc(100% - var(--slant))
  );
}

@media only screen and (max-width: 700px) {
  .opppertunities-container {
    padding-block: 95px;
    padding-inline: 22px;
  }
}
