.login-btn {
    background-color: #f7871b;
    color: white !important;
    padding: 10px 15px;
    display: inline-flex;
    border-radius: 4px;
    transition: all .5s ease-in-out;
}

.login-btn:hover {
    background-color: #8b4300;
}

.position-content p {
    margin-bottom: 0;
}

.my-selection-wrapper {
    border-radius: 5px;
    background-color: #006e7a;
    /* #646464; */
    padding: 7px 0;
    text-align: center;
}


.accordation-header button {
    background-color: rgb(46, 44, 44);
    color: white;
    font-weight: bold;
}

.accordion-button::after {

    background-image: url('https://img.icons8.com/color/48/000000/down-squared.png')
}

.closeBtn {
    display: flex;
    align-items: center;
    justify-content: end;
}

/* .walletDiv:hover {
    background-color: #25282c;
    transform: translateY(-4px)
} */

.grayanimation {
    animation: lightup 2s linear infinite;
}

@keyframes lightup {

    0% {
        background-color: rgba(128, 128, 128, 0.945);
        transform: scale(1);
    }

    25% {
        background-color: rgba(128, 128, 128, 0.645);
        transform: scale(1.03);
    }

    50% {

        background-color: #13202280;
        transform: scale(1.06);
    }

    75% {
        background-color: rgba(128, 128, 128, 0.645);
        /* background-color: rgba(128, 128, 128, 0.445); */
        transform: scale(1.03);
    }

    100% {
        /* background-color: #1e2a2c; */
        background-color: rgba(128, 128, 128, 0.945);
        transform: scale(1);
    }
}



.dialogDiv {
    border-radius: 20px;
}

.walletName {
    font-size: 1rem;
}


.modal-text {
    background: linear-gradient(to right, #ffffff 0, #ffaa00 20%, #ffffff 30%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.514);
    animation: shine 4s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    font-weight: 600;
    white-space: nowrap;
    cursor: pointer;
    display: block;
    color: white;
}

@-webkit-keyframes shine {
    0% {
        background-position: 0;
    }

    60% {
        background-position: 450px;
    }

    100% {
        background-position: 450px;
    }
}

@keyframes shine {
    0% {
        background-position: 0;
    }

    60% {
        background-position: 450px;
    }

    100% {
        background-position: 450px;
    }
}

.img-standing {
    width: 100%;
    /* height: 28rem; */
}

.position-content {
    position: relative;
    text-align: center;
}

.firstPosition {
    position: absolute;
    top: 3px;
    left: 50%;
    transform: translateX(-50%);
}

.secondPosition {
    position: absolute;
    top: 48px;
    left: 24%;
    transform: translateX(-76%);
}

.thirdPosition {
    position: absolute;
    top: 88px;
    right: 24%;
    transform: translateX(76%);
}




@media only screen and (max-width: 600px) {
    .walletName {
        font-size: 0.9rem;
    }

    .contents {
        font-size: 0.8rem;
    }

    .imgWalletBtn {
        width: 30px !important;
        margin: 0 auto;
    }

    .imgWallet {
        width: 45px;
        margin: 0 auto;
    }

    .content {
        margin-left: 1rem;
    }

    .position-content p {
        font-size: 13px;
    }

    .firstPosition {
        top: -3px;
    }

    .secondPosition {
        top: 27px;
    }

    .thirdPosition {
        top: 55px;
    }
}

@media only screen and (max-width: 500px) {

    .position-content .dog,
    .selection-dog img {
        width: 30px !important;
    }

    .position-content p,
    .loading-text {
        font-size: 11px;
    }

    .firstPosition {
        top: 0px;
    }

    .secondPosition {
        top: 10px;
    }

    .thirdPosition {
        top: 35px;
    }

    .position-table-body td,
    .position-table-header th,
    .my-selection p,
    .time-show p {
        font-size: 12px;
    }

    .position-table-body img,
    .my-selection img {
        width: 20px !important;
    }

    .my-selection p {
        padding: 5px 10px;
    }

    .claim-btn {
        font-size: 10px !important;
        padding: 2px 4px !important;
        margin-left: 4px !important;
    }
}

@media only screen and (max-width: 400px) {

    .position-content p {
        /* display: none; */
        font-size: 10px;
    }

    .firstPosition {
        top: -8px;
    }

    .secondPosition {
        top: 8px;
        left: 25%;
    }

    .thirdPosition {
        top: 25px;
        right: 25%;
    }

}

.banner-button2 {
    background-image: linear-gradient(to right, #FF512F 0%, #db1528 51%, #FF512F 100%);
    margin: 10px;
    padding: 8px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border: none;
    font-weight: bold;
}

.fontText {
    padding: 10px;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.banner-button2:hover {
    background-image: linear-gradient(to right, #db1528 0%, #FF512F 51%, #db1528 100%);
}

.iconCloses {
    padding: 1px !important;
    background-color: red !important;
    border: none !important
}

.closeD {
    text-align: right;
}

@media only screen and (max-width: 800px) {
    .claim-btn {
        font-size: 12px;
    }
}