.add-paypal-input {
    width: 100% !important;
  }
  .changeBTN{
    /* border: none;
    padding: 6px;
    border-radius: 5px;
    color: white;
    background-color: ; */
  }
  .affiBox{
    width: 40px;
    background-color: transparent;
    border: 1px solid yellow;
    padding: 5px;
    color: white;
    margin-left: 10px;
    border-radius: 5px;
  }
  
  .earnings-container {
    padding-block: 40px;
  }
  
  .each-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    background-color: #3080AC;
    border-radius: 9px;
    padding-inline: 28px;
    padding-block: 25px;
  }
  
  .balance-container {
    width: max-content;
    height: 260px;
    width: 260px;
    background: linear-gradient(209.66deg, #5358E7 7.78%, #54C3DD 81.86%);
    color: white;
    border-radius: 50%;
    margin-inline: autop;
    margin-inline: auto;
    margin-top: 30px;
  }
  
  .balance-usd {
    margin-bottom: -7px;
    margin-left: -7px;
    padding-top: 63px;
    padding-left: 81px;
  }
  
  .balance-amount {
    font-size: 3.5rem;
  }
  
  .payment-request-button {
    border: none;
    padding-block: 6px;
    padding-inline: 10px;
    border-radius: 5px;
    color: white;
    background-color: #03B760;
    padding-top: 5px;
    display: block;
    margin-right: 24%;
    margin-left: auto;
  }
  
  
  .second-part {
    background-color: #02162A;
    padding-block: 35px;
    margin-top: 30px;
  }
  
  .signups-filed {
    width: 60px;
    text-align: center;
    padding-block: 2px;
    font-size: 18px;
    margin-bottom: -4px;
    background-color: #D9D9D9;
    margin-left: 13px;
    border-radius: 3px;
    color: black;
  }
  
  .share-button {
    border: none;
    padding-block: 6px;
    padding-inline: 10px;
    border-radius: 2px;
    color: white;
    background-color: #03B760;
    padding-top: 5px;
  }
  
  .earnings-table {
    width: 100%;
    min-width: 470px;
  }
  
  .earnings-table-container {
    background-color: #D9D9D9;
    padding-inline: 36px;
    padding-block: 16px;
    border-radius: 6px;
    margin-top: 20px;
    margin-bottom: 24px;
    overflow-x: auto;
  }
  
  .earnings-table td {
    padding-block: 10px;
  }
  
  .earnings-table tbody tr td {
    color: #514646;
  }
  
  @media only screen and (max-width: 700px) {
    .earnings-table-container {
      padding-inline: 10px;
      padding-block: 10px;
    }
    .payment-request-button {
      margin-top: 16px;
      margin-right: 0px;
  }
  }
  
  @media only screen and (max-width: 400px) {
    .add-paypal-input {
      width: 100% !important;
    }
  }
  