.leader-dropdown {
    width: 20%;
    margin-left: auto;
}

@media only screen and (max-width: 700px) {
    .leader-dropdown {
        width: 50%;
        margin: 0 auto;
    }

}