.for-just-100-container {
    background: linear-gradient(90deg, #332D6B 0%, #232B59 100%);
}

.premium-membership {
    font-size: 56px;
}

.what-you-get-title {
    color: #FBB206;
    border-bottom: 1px solid;
    display: inline;
    padding-bottom: 6px;
    border-width: initial;
}

.you-get-1 {
    height: 357px;
    background: url("../../Assets/Images/you-get-1.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.you-get-2 {
    height: 357px;
    background: url("../../Assets/Images/you-get-2.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.you-get-3 {
    height: 357px;
    background: url("../../Assets/Images/you-get-3.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.payment-button {
    --slant: 0.7em;
    border: none;
    /* background: #4583ff; */
    padding-inline: 30px;
    padding-block: 11px;
    /* font-family: "Inter"; */
    font-weight: 500;
    font-size: 18px;
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #ea9606 100%);    
    margin-top: 32px;
    clip-path: polygon(0 0, calc(100% - var(--slant)) 0, 100% var(--slant), 100% 100%, var(--slant) 100%, 0 calc(100% - var(--slant)));
}

@media only screen and (max-width:761px) {
    .premium-membership {
        font-size: 36px;
        margin-bottom: 28px !important;
    }   
}

@media only screen and (max-width:430px) {
    .premium-membership {
        font-size: 28px;
    }   
}